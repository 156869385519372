jQuery(document).ready(function($) {


	// Lazy loading images

	let lazyloadImages;    

	if ('IntersectionObserver' in window) {

		let lazyloadImages = document.querySelectorAll('.lazy');

		let imageObserver = new IntersectionObserver( function(entries, observer) {

			entries.forEach((entry) => {

				if (entry.isIntersecting) {

					let image = entry.target;
					image.src = image.dataset.src;
					image.classList.remove('lazy');
					imageObserver.unobserve(image);

				}

			});

		});

		lazyloadImages.forEach(function(image) {

			imageObserver.observe(image);

		});

	} else { 

		let lazyloadThrottleTimeout;

		lazyloadImages = document.querySelectorAll('.lazy');

		function lazyload () {

			if(lazyloadThrottleTimeout) {

				clearTimeout(lazyloadThrottleTimeout);

			}    

			lazyloadThrottleTimeout = setTimeout(function() {

				let scrollTop = window.pageYOffset;

				lazyloadImages.forEach(function(img) {

					if(img.offsetTop < (window.innerHeight + scrollTop)) {

						img.src = img.dataset.src;
						img.classList.remove('lazy');

					}

				});

				if(lazyloadImages.length == 0) { 

					document.removeEventListener('scroll', lazyload);
					window.removeEventListener('resize', lazyload);
					window.removeEventListener('orientationChange', lazyload);

				}

			}, 20);

		}

		document.addEventListener('scroll', lazyload);
		window.addEventListener('resize', lazyload);
		window.addEventListener('orientationChange', lazyload);
	}



	// Bootstrap selectpicker close on select
	$('.selectpicker').on('change',function() {
		$(this).selectpicker('toggle');
	});



	// Visiblità bottoni + e - in .js-quantity (check data-quantitymax)

	if( $('.js-quantity') ) {

		let load = false;

		$(window).on('load', function () {

			if(!load) {
				let inputs = $('.js-quantity');
				inputs.each(function() {
					let cv = $(this).find('[id^="prevamount"]').val();
					let mv = $(this).find('.js-result').data('quantitymax');
					if (cv >= mv ) {
						$(this).find('.js-result').attr('value', mv).val(mv);
						$(this).find('[id^="prevamount"]').attr('value', mv).val(mv);
						$(this).find('.js-plus').addClass('invisible');
					}
/*					if(cv==0){
						$('#singleprod_addtocart').addClass('invisible');
					}*/
				});
			}
			load = true;
		});

		$(document).on('click', '.js-minus', function (event) {

			event.preventDefault();

			let currentValue = $(this).parent().prev().find('[id^="prevamount"]').val();
			let maxValue = $(this).parent().prev().find('.js-result').data('quantitymax');
			let inputResult = $(this).parent().prev().find('.js-result');
			let plus = $(this).next();

			if ( currentValue === maxValue ) {
				inputResult.attr('value', currentValue).val(currentValue);
				plus.removeClass('invisible');
			} else if ( currentValue < (maxValue + 1) ) {
				$('#alertQuantitymax').addClass('d-none');
				plus.removeClass('invisible');
				$('#singleprod_addtocart').removeClass('btn-dark disabled').addClass('btn-blue-cart');
			}

		})

		$(document).on('click', '.js-plus', function (event) {

			event.preventDefault();

			let currentValue = $(this).parent().prev().find('[id^="prevamount"]').val();
			let maxValue = $(this).parent().prev().find('.js-result').data('quantitymax');
			let inputResult = $(this).parent().prev().find('.js-result');

			if (currentValue >= (maxValue - 1) ) {
				$('#alertQuantitymax').removeClass('d-none');
				inputResult.attr('value', maxValue).val(maxValue);
				$(this).addClass('invisible');
			} else {
				$('#alertQuantitymax').addClass('d-none');
				$(this).removeClass('invisible');
			}

		})

	}

	//Modal addtocart
	$('.btn-add-cart').on('click',function(event){
		var productID = $(this).data('productid');
		var quantity = 1;

		if( document.querySelector("#langsc") ){
			var langsc = $('#langsc').val();
		} else {
			let pathArray = window.location.pathname.split('/');
			var langsc = pathArray[1];
		}
		let ajaxUrl = '/' + langsc + '/add_to_cart';

		$.ajax({
			url         : ajaxUrl,
			data        : JSON.stringify({"productId":productID,"quantity":quantity}),
			cache       : false,
			contentType : false,
			processData : false,
			type        : 'POST',
			dataType    : "json",
			success     : function(data, textStatus, jqXHR) {

				if(data.success){
					if ( typeof ( data.datalayer ) !== 'undefined' && data.datalayer !== null ) {
						window.dataLayer.push(JSON.parse(data.datalayer));
					}
					$('#modaladdtocart')[0].innerHTML = $('#modaladdtocart')[0].innerHTML.replace('##title##',  $('<div>').html(data.message).text()) ;
					$('#modaladdtocart')[0].innerHTML = $('#modaladdtocart')[0].innerHTML.replace('##gotocart##',  $('<div>').html(data.gotocart).text()) ;
					$('#modaladdtocart')[0].innerHTML = $('#modaladdtocart')[0].innerHTML.replace('##continuetext##',  $('<div>').html(data.continuetext).text()) ;
					Swal.fire({
						template: '#modaladdtocart',
					});
					if(!$('#cart-quantity').hasClass('bg-white')) {
						$('#cart-quantity').addClass('bg-white')
					}
					$('#cart-quantity').html(data.cartqty);
				}else{
					Swal.fire({
						text:  $('<div>').html(data.message).text(),  // for encoding html entities
						icon:  'error',
						showCancelButton: false,
						confirmButtonText: "Ok",
						confirmButtonColor: '#0D4DA1',
					});
				}
			},
			statusCode: {
				404: function() {
					alert( "page not found" );
				}
			}
		});
	});





	// Clone desktop sidebar single product filters to mobile aside offcanvas and vice versa

	const mediaQuery = '(max-width: 1199px)';
	const mediaQueryList = window.matchMedia(mediaQuery);

	if (mediaQueryList.matches) {

		$('#offcanvasFilters').append($('#sidebarFilters').children().clone());
		$('#sidebarFilters').empty();

	}

	mediaQueryList.addEventListener('change', event => {

		if (event.matches) {

			$('#offcanvasFilters').append($('#sidebarFilters').children().clone());
			$('#sidebarFilters').empty();

		} else {

			$('#sidebarFilters').append($('#offcanvasFilters').children().clone());
			$('#offcanvasFilters').empty();

		}

	})

});